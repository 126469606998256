import "./Footer.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = ({lng}) => {
  const { t } = useTranslation();

  return (
    <div
      className="footer"
    >
      <div className="footer-content">
        <div className="footer-logo">
        <Link to={lng !== "en" ? `/${lng}.index.html` : `/`}>
          <img src={"/logo-footer-coding.png"} alt="Is coding for you Logo" className="logo-image-footer" width="231" height="105"/>
        </Link>
        </div>
        <div>

        </div>
        <div className="terms">
          <div className="terms-left">
            <Link to={lng !== "en" ? `/${lng}.coding-test.html` : `/coding-test.html`} title="About test" className="footer-link">{t("About test")}</Link>
            <Link to={lng !== "en" ? `/${lng}.blog.html` : `/blog.html`} title="Blog" className="footer-link">{t("Blog")}</Link>
            <Link to={lng !== "en" ? `/${lng}.contact.html` : `/contact.html`} title="Contact us" className="footer-link">{t("Contact Us")}</Link>
          </div>
          <div className="terms-right">
            <Link to={lng !== "en" ? `/${lng}.affiliate.html` : `/affiliate.html`} title="affiliate program" className="footer-link">{t("Affiliate program")}</Link>
            <Link to={lng !== "en" ? `/${lng}.privacy.html` : `/privacy.html`} title="Privacy Policy" className="footer-link">{t("Privacy Policy")}</Link>
            <Link to={lng !== "en" ? `/${lng}.terms-of-use.html` : `/terms-of-use.html`} title="Terms Of Use" className="footer-link">{t("Terms Of Use")}</Link>
          </div>
        </div>
      </div>
      <div className="company-name">
        <p>XB Software Solutions LLC</p>
      </div>
      <div className="company-address">
        <div className="company-address-wrap">
          <div className="address-info">
            <p>30 North Gould Street Sheridan,</p>
            <p>82801 WY United States</p>
          </div>
          <div className="address-info">
            <p>7b Antuna Urbana, Novi Sad,</p>
            <p>Vojvodina 21137, Serbia</p>
          </div>
        </div>
        
        <a className="company-address-link" href = "mailto: support@iscodingforyou.com">support@iscodingforyou.com</a>
      </div>
      <div className="rights">
        <p>© 2023 iscodingforyou.com. {t("All rights reserved")}</p>
      </div>
    </div>
  );
};

export default Footer;
