import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import "./Question.css";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { fadeIn, rotateInDownRight } from 'react-animations';
import { StyleRoot, keyframes } from 'radium';
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import FormModal from "../Modal/FormModal"
import axios from "axios";
import { useCookies } from 'react-cookie';

const Question = ({
  currQues,
  setCurrQues,
  questions,
  options,
  setQuestions,
  answers,
  setAnswers,
  currQuesLogical,
  setCurrQuesLogical,
  currQuesPersonality,
  setCurrQuesPersonality,
  currQuesEnglish,
  setCurrQuesEnglish,
  requireEnglish,
  setCompleteLogicalQuestion,
  setCompletePersonalityQuestion,
  lng
}) => {
  const [selected, setSelected] = useState();
  const [time, setTime] = useState(1);
  const [error, setError] = useState(false);
  const [errorB3, setErrorB3] = useState(false);
  const [currQuestionOptions, setCurrQuestionOptions] = useState();
  const { t, i18n } = useTranslation();
  const [openFormModal, setOpenFormModal] = useState(false);
  const handleOpenFormModal = () => setOpenFormModal(true);
  const handleCloseFormModal = () => setOpenFormModal(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showTitleAnimation, setShowTitleAnimation] = useState(false);
  const [cookies] = useCookies(['affcode']);

  const history = useHistory();

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  const handleSelect = (i) => {
    if (selected === i) return "option-selected";
    return ""
  };

  const handleCheck = (i) => {
    setSelected(i);
    const id = questions[currQues]._id;
    const answer = {
      id: id,
      answer: i,
      time: time
    }
    let createdAnswers = [];

    if (answers) {
      createdAnswers = answers;
    }

    createdAnswers = createdAnswers.filter(x => {
      return x.id != id;
    })

    createdAnswers.push(answer);
    setAnswers(createdAnswers)

    setError(false);
    // handleNext();
    setTimeout(
      () => handleNext(), 
      200
    );
  };

  const handleNext = () => {
    const currentQuestion = currQues + 1;
    window.localStorage.removeItem('timer')

    if(questions[currentQuestion]) {
      let currentAnswer = 0;
      if (answers) {
        currentAnswer = answers.find(x => x.id === questions[currentQuestion]._id)
      }

      if (currentAnswer) {
        setTime(currentAnswer.time);
      } else {
        setTime(0);
      }
    }

    let nextQues = currQues + 1;

    if (currentQuestion >= questions.length) {
        setOpenFormModal(true);
        setSelected();
    } else if(!requireEnglish && questions[nextQues].group === 'english') {
        setOpenFormModal(true);
        setSelected();
    } else {
      setCurrQuestionOptions(null);
      setCurrQues(nextQues);
      setSelected();
      let personalityNextQuestion = ['personality', 'personality-qa', 'personality-managment'].includes(questions[nextQues].group);
      let personalityCurrQuestion = ['personality', 'personality-qa', 'personality-managment'].includes(questions[currQues].group);

      if(questions[nextQues].group === 'iq') {
        setCurrQuesLogical(currQuesLogical + 1)
      }
      if(questions[currQues].group === 'iq' && personalityNextQuestion) {
        setCompleteLogicalQuestion(true);
      }
      if(personalityNextQuestion) {
        setCurrQuesPersonality(currQuesPersonality + 1)
      }
      if(personalityCurrQuestion && questions[nextQues].group === 'english') {
        setCompletePersonalityQuestion(true);
      }
      if(questions[nextQues].group === 'english') {
        setCurrQuesEnglish(currQuesEnglish + 1)
      }
    };
  };

  useEffect(() => {
    if (localStorage.getItem("timer") != null) {
      setTime(JSON.parse(window.localStorage.getItem('timer')));
    }

    if (currQuesLogical === 1) {
      setTimeout(
        () => {
          const intervalId = setInterval(() => {
            setTime(prevTime => {
              const newTime = prevTime + 1;
              window.localStorage.setItem('timer', JSON.stringify(newTime));
              return newTime;
            });
          }, 1000);
        }, 
        3000
      );
    } else {
      const intervalId = setInterval(() => {
        setTime(prevTime => {
          const newTime = prevTime + 1;
          window.localStorage.setItem('timer', JSON.stringify(newTime));
          return newTime;
        });
      }, 1000);
    }
  }, []);

  useEffect(() => {
    setCurrQuestionOptions(questions[currQues].options);
  }, [currQues]);

  useEffect(() => {
    if (currQuesLogical === 1 || currQuesPersonality === 1 || currQuesEnglish === 1) {
      setShowTitleAnimation(true)
      setTimeout(
        () => {
          setShowTitleAnimation(false)
        }, 
        3000
      );
    }
  }, [currQuesLogical, currQuesPersonality, currQuesEnglish]);

  const currQuestionComponentValue = questions[currQues];

  const styles = {
    fadeIn: {
      animation: 'x 0.3s',
      animationName: keyframes(fadeIn, 'fadeIn'),
      animationDelay: '0.1s',
      animationFillMode: 'forwards',
      opacity: 0
    },
    fadeIn2: {
      animation: 'x 0.3s',
      animationName: keyframes(fadeIn, 'fadeIn'),
      animationDelay: '0.2s',
      animationFillMode: 'forwards',
      opacity: 0
    },
    fadeIn3: {
      animation: 'x 0.3s',
      animationName: keyframes(fadeIn, 'fadeIn'),
      animationDelay: '0.3s',
      animationFillMode: 'forwards',
      opacity: 0
    },
    fadeIn4: {
      animation: 'x 2s',
      animationName: keyframes(rotateInDownRight, 'rotateInDownRight'),
      animationDelay: '0s',
      animationFillMode: 'forwards',
      opacity: 0
    }
  }

  const showTime = (time) => {
    if(time >= 60) {
      var minutes = Math.floor(time / 60);
      var seconds = time - minutes * 60;
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds
      }
      return minutes + ":" + seconds
    }
    if (time < 10) {
      return "00:0" + time;
    }
    return "00:" + time;
  };

  const handlePreviousQuestion = () => {
    window.localStorage.removeItem('timer')
    let prevQues = currQues - 1;

    if (currQues > 0) {
      const id = questions[currQues]._id;
      const answer = {
        id: id,
        answer: "",
        time: time
      }
      let createdAnswers = [];
      if (answers) {
        createdAnswers = answers;
      }
      createdAnswers = createdAnswers.filter(x => {
        return x.id != id;
      })
      createdAnswers.push(answer);
      setAnswers(createdAnswers);

      const prevAnswer = answers.find(x => x.id === questions[prevQues]._id)
      setTime(prevAnswer.time);
    }
    

    if (currQues > 0) {
      setCurrQues(prevQues);
      setSelected();
      let personalityPrevQuestion = ['personality', 'personality-qa', 'personality-managment'].includes(questions[prevQues].group);
      let personalityCurrQuestion = ['personality', 'personality-qa', 'personality-managment'].includes(questions[currQues].group);

      if(questions[prevQues].group === 'iq' && questions[currQues].group === 'iq') {
        setCurrQuesLogical(currQuesLogical - 1)
      }
      if(questions[prevQues].group === 'iq' && personalityCurrQuestion) {
        setCompleteLogicalQuestion(false);
        setCurrQuesPersonality(currQuesPersonality - 1)
      }
      if(personalityPrevQuestion && personalityCurrQuestion) {
        setCurrQuesPersonality(currQuesPersonality - 1)
      }
      if(personalityPrevQuestion && questions[currQues].group === 'english') {
        setCompletePersonalityQuestion(false);
        setCurrQuesEnglish(currQuesEnglish - 1)
      }
      if(questions[prevQues].group === 'english') {
        setCurrQuesEnglish(currQuesEnglish - 1)
      }
      setCurrQuestionOptions(null);
    };
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    try {
      const { data } = await submitTest(event);

      if (data.success && data.testId) {
        if (data.level === 'B3') {
          setErrorB3(true);
          setOpenFormModal(false);
        } else {
          window.localStorage.setItem('testId', JSON.stringify(data.testId));
          window.localStorage.setItem('customerEmail', JSON.stringify(event.target.email.value));
          window.localStorage.setItem('customerName', JSON.stringify(event.target.name.value));
          let analysisRoute = lng !== "en" ? `/${lng}.analysis.html` : `/analysis.html`;
          history.push(analysisRoute);
        }
      } else {
          setError(true);
          setOpenFormModal(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const submitTest = async (event) => {
    try {
        const affcodeFromCookie = cookies.affcode;

        const data = {
            answers: answers,
            email: event.target.email.value,
            name: event.target.name.value,
            age: event.target.age.value,
            translateLanguage: i18n.language,
            affCode: affcodeFromCookie ? affcodeFromCookie : "" 
        }

        const response = await axiosInstance.post("api/submitTest", data);

        return response;
    } catch (e) {
        console.error(e);
        return null
    }
  };


  return (
    <div className="question">
      <FormModal
        handleSubmit={handleSubmit}
        openFormModal={openFormModal}
        handleCloseFormModal={handleCloseFormModal}
      />
      {error && <ErrorMessage>{t("Something went wrong. Our team is working to solve it.")}</ErrorMessage>}
      {errorB3 && <div>
        <ErrorMessage>{t("Hello! Our software is designed to assess your potential and provide personalized guidance based on your answers. It appears that the system has detected inaccurate responses. Please consider retaking the test with the correct answers for accurate results.")}
        <div className="cancel-button-wrap">
          <NavLink to={lng !== "en" ? `/${lng}.index.html` : `/`} title="Cancel test">
            <Button
              style={{
                backgroundColor: "#f2f2f2"
              }}
              variant="text"
              size="large"
              className="cancel-button"
            >
              {t("RETAKE TEST")}
            </Button>
          </NavLink>
        </div>
        </ErrorMessage>
        
      </div>}



      {showTitleAnimation ? (
        <div className="singleQuestion singleQuestionAnimation">

          {currQuesLogical === 1 && <div className="">
            <StyleRoot>
              <p className="logical-animation" style={styles.fadeIn4}>{t("Problem solving")}</p>
              <p className="logical-animation" style={styles.fadeIn4}>{t("Logical test")}</p>
              {/* <p className="logical-animation" style={styles.fadeIn4}>{t("Test")}</p> */}
            </StyleRoot>
          </div>}

          {currQuesPersonality === 1 && <div className="">
            <StyleRoot>
              <p className="logical-animation" style={styles.fadeIn4}>{t("Personality test")}</p>
              {/* <p className="logical-animation" style={styles.fadeIn4}>{t("Test")}</p> */}
            </StyleRoot>
          </div>}

          {currQuesEnglish === 1 && <div className="">
            <StyleRoot>
              <p className="logical-animation" style={styles.fadeIn4}>{t("English language test")}</p>
            </StyleRoot>
          </div>}
        </div>
      ) : (
        <>
          <div className="question-header">
          <div>
            {currQuestionComponentValue.group === "iq" ? (
              <p className="question-number">{t("Logical test")}</p>
            ) : (
              <>
                {currQuestionComponentValue.group === "english" ? (
                  <p className="question-number">{t("English language")}</p>
                ) : (
                  <p className="question-number">{t("Personality test")}</p>
                )}
              </>
            )}
          </div>
          <p className="question-number question-number-left">{t("Question")}: {currQues + 1}</p>
          
          </div>
          {currQuestionComponentValue.group === "iq" ? (
              <h1 className="question-time">{String.fromCodePoint(0x1F551) + " "}<span className="time-box">{showTime(time)}</span></h1>
            ) : (
              <div></div>
            )}

          <div className="singleQuestion">
            {!currQuestionComponentValue.image ? (
              <>
                <div className="single-question-text-wrap">
                  <h2 className={['personality', 'personality-qa', 'personality-managment'].includes(currQuestionComponentValue.group) ? "single-question-text single-question-text-personality" : (currQuestionComponentValue.group === 'english' ? "options single-question-text-english" : "single-question-text")}>{t(currQuestionComponentValue.questionId)}</h2>
                </div>
                <div className={['personality', 'personality-qa', 'personality-managment'].includes(currQuestionComponentValue.group) ? "options options-personality options-personality-"+i18n.language : (currQuestionComponentValue.group === 'english' ? "options options-english" : "options")}>
                  {currQuestionOptions &&
                    currQuestionOptions.map((i, index) => (
                      <div key={i} className={['personality', 'personality-qa', 'personality-managment'].includes(currQuestionComponentValue.group) ? "answer-wrap-personality answer-wrap-personality-"+i18n.language : (currQuestionComponentValue.group === 'english' ? (i.length > 13 ? "answer-wrap-english-full" : "answer-wrap-english") : "answer-wrap")}>
                        <StyleRoot>
                          <button
                            className={`${['personality', 'personality-qa', 'personality-managment'].includes(currQuestionComponentValue.group) ? ("singleOptionPersonality singleOptionPersonality-"+i18n.language) : "singleOption"} ${handleSelect(i)}`}
                            // className={`singleOption  ${selected && handleSelect(i)}`}
                            onClick={() => handleCheck(i)}
                            disabled={selected}
                            style={index === 0 || index === 1 ? styles.fadeIn : (index === 2 || index === 3 ? styles.fadeIn2 : styles.fadeIn3)}
                          >
                            {t(i)}
                          </button>
                        </StyleRoot>
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <><div className="single-question-image-container">
                <img src={"questions/" + currQuestionComponentValue.questionId} alt="image" className="single-question-image" loading="lazy" />
              </div>
                <div className="options images">
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                  {currQuestionOptions &&
                    currQuestionOptions.map((i, index) => (

                      <div className="answer-wrap" key={i}>
                        <StyleRoot>
                          <button
                            className={`singleOption-image ${handleSelect(i)}`}
                            // className={`singleOption  ${selected && handleSelect(i)}`}
                            onClick={() => handleCheck(i)}
                            disabled={selected}
                            style={index === 0 || index === 1 ? styles.fadeIn : (index === 2 || index === 3 ? styles.fadeIn2 : styles.fadeIn3)}
                          >
                            {/* {i} */}
                            <img src={"questions/" + i} alt="image" className="image-answer" loading="lazy"/>
                          </button>
                        </StyleRoot>
                      </div>
                    ))}
                </div></>
            )}
          </div>
          <Button
            variant="outlined"
            size="medium"
            onClick={handlePreviousQuestion}
            className="previous-question"
          >
            {String.fromCodePoint(0x2190)} {t("Previous")}
          </Button>
        </>
      )}
           

      
    
    </div>
  );
};

export default Question;
