import { Button, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { fadeInRight, fadeInLeft, fadeIn, fadeInUp } from 'react-animations';
import { StyleRoot, keyframes } from 'radium';
import "./About.css";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { useTranslation } from "react-i18next";
import BasicModal from "../../components/Modal/BasicModal"
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga4';

const About = ({ fetchQuestions, openModal, handleOpenModal, handleCloseModal, removeLocalStorage, lng }) => {
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0)
    ReactGA.send({ hitType: "pageview", page: window.location.pathname});
  }, []);

  const handleSubmit = async () => {
    handleCloseModal();
    const data = await fetchQuestions();

    if (Array.isArray(data)) {
      removeLocalStorage();

      let testRoute = lng !== "en" ? `/${lng}.testing.html` : `/testing.html`;
      history.push(testRoute);
    } else {
      window.scrollTo(0, 0)
      setError(true);
    }
  };

  const useStyles = makeStyles({
    button2: {
      backgroundColor: "#F49F1C",
      borderRadius: "30px",
      fontSize: "20px",
      padding: "20px 30px",
      '&:hover': {
        backgroundColor: '#030E4F',
        "& .MuiButton-label": {
          color: '#F49F1C'
        }
      }
    }
  })

  const classes = useStyles()

  const styles = {
    fadeInUp: {
      animation: 'x 0.5s',
      animationName: keyframes(fadeInUp, 'fadeInUp'),
      animationDelay: '1.3s',
      animationFillMode: 'forwards',
      opacity: 0
    },
    fadeInRight: {
      animation: 'x 0.8s',
      animationName: keyframes(fadeInRight, 'fadeInRight')
    },
    fadeInLeft1: {
      animation: 'x 0.8s',
      animationName: keyframes(fadeInLeft, 'fadeInLeft')
    },
    fadeInLeft2: {
      animation: 'x 0.8s',
      animationName: keyframes(fadeInLeft, 'fadeInLeft'),
      animationDelay: '0.2s',
      animationFillMode: 'forwards',
      opacity: 0
    },
    fadeIn: {
      animation: 'x 1s',
      animationName: keyframes(fadeIn, 'fadeIn'),
      animationDelay: '0.9s',
      animationFillMode: 'forwards',
      opacity: 0
    },
    fadeIn2: {
      animation: 'x 1s',
      animationName: keyframes(fadeIn, 'fadeIn'),
      animationDelay: '1s',
      animationFillMode: 'forwards',
      opacity: 0
    },
    fadeIn3: {
      animation: 'x 1s',
      animationName: keyframes(fadeIn, 'fadeIn'),
      animationDelay: '1.1s',
      animationFillMode: 'forwards',
      opacity: 0
    },
    fadeIn4: {
      animation: 'x 1s',
      animationName: keyframes(fadeIn, 'fadeIn'),
      animationDelay: '1.2s',
      animationFillMode: 'forwards',
      opacity: 0
    },
    fadeIn5: {
      animation: 'x 1s',
      animationName: keyframes(fadeIn, 'fadeIn'),
      animationDelay: '1.3s',
      animationFillMode: 'forwards',
      opacity: 0
    }
  }

  return (
    <div>
      <Helmet>
        {/* <title>Coding Potential Test for Beginners</title> */}
        <title>{t("Test for beginners who want to")} {" "} {t("learn to code")}</title>
        <link rel="canonical" href={lng !== "en" ? `https://iscodingforyou.com/${lng}.coding-test.html` : `https://iscodingforyou.com/coding-test.html`} />
        <meta name="description" content={t("Quickly and easily find out if you have the potential for coding")}/>
        <meta property="og:title" key="og:title" content="Coding Potential Test for Beginners" />
        <meta property="og:locale" key="og:locale" content="en_US" />
        <meta property="og:site_name" key="og:site_name" content="Is coding for you" />
        <meta property="og:url" key="og:url" content="https://iscodingforyou.com/coding-test.html" />
        <meta charSet="utf-8" />
        <meta property="og:type" key="og:type" content="website" />
        <meta
          property="og:description"
          key="og:description"
          content={t("Quickly and easily find out if you have the potential for coding")}
        />
        <meta
          property="og:image"
          key="og:image"
          content="https://iscodingforyou.com/about-image.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="https://iscodingforyou.com/" />
        <meta name="twitter:title" content="Coding Potential Test for Beginners" />
        <meta name="twitter:image" content="https://iscodingforyou.com/about-image.png"/>
        <meta name="twitter:description" content={t("Quickly and easily find out if you have the potential for coding")} />
      </Helmet>
      <BasicModal
        handleSubmit={handleSubmit}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      />
      <div className="content-about">
        <div className="settings-about">
          <div className="settings__select">
            {error && <ErrorMessage>{t("Something went wrong. Our team is working to solve it.")}</ErrorMessage>}
            <StyleRoot>
              <h1 className="home-title-en about-title-en" style={styles.fadeInLeft1}>
              {t("Test for beginners who want to")} <span className="title-coding-about">{t("learn to code")}</span> 
              </h1>
              {/* <h2 className="home-title-2 about-text" style={styles.fadeInLeft2}>
                {t("If you're a beginner who wants to learn coding, you're in the right place")}
              </h2> */}
              <h2 className="home-title-2 about-text" style={styles.fadeInLeft2}>
                {t("Quickly and easily find out if you have the potential for coding")}
              </h2>
              <p className="home-title-3 about-title-3 about-text" style={styles.fadeIn}>
                {t("This test is created to help you make the right decision.")}
              </p>
              <p className="home-title-3 about-title-3 about-text" style={styles.fadeIn}>
                {t("No coding knowledge is required for this test.")}
              </p>
              <p className="home-title-3 about-title-3 about-text" style={styles.fadeIn}>
                {t("You will need about 15 minutes for this test.")}
              </p>
              <p className="home-title-3 about-title-3 about-text" style={styles.fadeIn}>
                {t("Get comfortable and start the test.")}
              </p>
              <p className="home-paragraph-about about-text" style={styles.fadeIn}>
                {t("This test is developed by experts from the field of programming.")}
              </p>
            </StyleRoot>
          </div>
        </div>
        <div className="banner-about">
          <StyleRoot>
            <img src="/about-image2.png" className="banner-image app-image" style={styles.fadeInRight} alt="Coding test est for beginners" width="530" height="502.72"/>
          </StyleRoot>
        </div>
      </div>
      <div className="home-section-2">
        <div className="content-2">
          <div className="settings-about-2">
            <p className="about-goals">{t("Goals of this test")}</p>
            <ul className="features-list-2">
              <li>
                <p>{t("To determine if coding is for you.")}</p>
              </li>
              <li>
                <p>{t("If coding is not for you, the result will provide you with an alternate route in the IT world.")}</p>
              </li>
              <li>
                <p>{t("If coding is for you, to provide you with guidelines upon completion.")}</p>
              </li>
              {/* <li>
                <p>{t("If you have potential you should be doing the most wanted and best-paid job!")}</p>
              </li> */}
            </ul>
            <p className="home-ready-2">{t("Are you ready?")}</p>

            <Button
              className={classes.button2}
              variant="contained"
              size="large"
              onClick={handleOpenModal}
            >
              {t("Start test")} {String.fromCodePoint(0x2192)}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
