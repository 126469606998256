import { useEffect } from "react";
import "./TermsOfUse.css";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet-async';

const TermsOfUse = ({lng}) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
 

  return (
    <div className="termsOfUse">
      <Helmet>
        <title>{t("Terms Of Use")}</title>
        <link rel="canonical" href={lng !== "en" ? `https://iscodingforyou.com/${lng}.terms-of-use.html` : `https://iscodingforyou.com/terms-of-use.html`} />
        <meta name="description" content={t("Welcome to Is Coding For You. By accessing and using our website, you agree to be bound by the following Terms of Use.")}/>
      </Helmet>
      <div className="content-termsOfUse">
        <h1>{t("Terms Of Use")}:</h1>
        <br/>
        <p>
          {t("Welcome to Is Coding For You. By accessing and using our website, you agree to be bound by the following Terms of Use. If you do not agree to these Terms of Use, please do not use our website.")}
        </p>

        <h2>1. {t("Use of our Website")}</h2>

        <p>
          {t("You may use our website for lawful purposes only. You may not use our website:")}
        </p>

        <ul>
          <li>
            {t("In any way that violates any applicable law or regulation")}
          </li>
          <li>
            {t("To engage in any conduct that is harmful, fraudulent, or illegal")}
          </li>
          <li>
            {t("To transmit or upload any content that is offensive, obscene, or defamatory")}
          </li>
          <li>
            {t("To interfere with or disrupt our website or its functionality")}
          </li>
          <li>
            {t("To circumvent any security or access controls")}
          </li>
        </ul>

        <p>
          {t("We reserve the right to terminate your access to our website at any time and for any reason, including if you violate these Terms of Use.")}
        </p>

        <h2>
          2. {t("Intellectual Property")}
        </h2>

        <p>
          {t("Our website and its content, including text, graphics, images, and software, are protected by copyright and other intellectual property laws. You may not copy, modify, distribute, or reproduce any part of our website or its content without our prior written consent.")}
        </p>

        <h2>
          3. {t("Online Test")}
        </h2>
        <p>
          {t("Welcome to our online test, a valuable tool designed for educational and informational purposes. This test is thoughtfully crafted to assess your potential for coding. However, it's crucial to understand that the results provided are not a definitive measure of your capability to become a programmer.")}
        </p>
        <p>
          {t("The outcomes are intended to offer insights and guidance, serving as a helpful resource for self-assessment and skill development. It's essential to recognize that individual performance and aptitude are multifaceted, influenced by various factors beyond the scope of a single test. As such, we emphasize that the results should be viewed as part of your broader learning journey.")}
        </p>
        <p>
          {t("We do not provide guarantees or representations regarding the accuracy or reliability of the test results. Instead, we encourage users to leverage this tool responsibly, understanding its limitations, and utilizing the insights gained to enhance their coding abilities. Embrace the results as a positive step in your learning process and continue your journey toward coding excellence.")}
        </p>
        

        <h2>
          4. {t("Refund Policy")}
        </h2>
        <p>
          {t("We strive for your satisfaction. However, due to the nature of our services (online coding potential tests), refunds are generally not applicable once the test has been initiated. Please carefully consider your decision before starting the test.")}
        </p>

        <h2>
          5. {t("Dispute Resolution")}
        </h2>
        <p>
          {t("In the event of a dispute or concern, please reach out to our customer support at support@iscodingforyou.com for prompt resolution. We are committed to addressing and resolving any issues swiftly.")}
        </p>


        <h2>
          6. {t("Transaction Currency")}
        </h2>
        <p>
          {t("All transactions on our platform are processed in US Dollars.")}
        </p>

        <h2>
          7. {t("Terms and Conditions of Any Promotions")}
        </h2>
        <p>
          {t("Promotional offers and discounts may be available from time to time. Users are advised to carefully review and agree to the specific terms and conditions associated with each promotion before availing of any discounts or special offers. Failure to adhere to these terms may result in the forfeiture of promotional benefits.")}
        </p>


        <h2>
          8. {t("Security Capabilities and Policy for Transmission of Payment Card Details")}
        </h2>
        <p>
          {t("The security of your payment information is of utmost importance to us. Our website employs industry-standard encryption protocols to safeguard the transmission of payment card details. We utilize secure and trusted payment processors to ensure the highest level of security for our users. Rest assured that your sensitive information is handled with the utmost care and in compliance with industry security standards.")}
        </p>

        <h2>
          9. {t("Disclaimer of Warranties")}
        </h2>
        <p>
          {t("We provide our website and its content on an 'as is' and 'as available' basis, without any warranties of any kind, express or implied. Specifically, we make no guarantees regarding the suitability of the online test for any specific purpose or the accuracy of the information obtained through the test. Users acknowledge that individual experiences may vary, and the test is not a guaranteed predictor of programming abilities.")}
        </p>

        <h2>
          10. {t("Limitation of Liability")}
        </h2>
        <p>
          {t("In no event shall we be liable for any direct, indirect, incidental, consequential, special, or punitive damages arising out of or in connection with your use of our website or its content, even if we have been advised of the possibility of such damages. Our liability to you shall not exceed the amount you paid, if any, to use our website.")}
        </p>

        <h2>
          11. {t("Indemnification")}
        </h2>

        <p>
          {t("You agree to indemnify and hold us harmless from any claims, damages, losses, liabilities, and expenses, including reasonable attorneys' fees, arising out of or in connection with your use of our website or your violation of these Terms of Use.")}
        </p>

        <h2>
          12. {t("Governing Law")}
        </h2>
        <p>
          {t("These Terms of Use shall be governed by and construed in accordance with the laws of Wyoming United States, without regard to its conflict of law provisions.")}
        </p>

        <h2>
          13. {t("Contact Information")}
        </h2>
        <p>
          {t("If you have any questions, concerns, or require assistance, please don't hesitate to reach out to our customer support. You can contact us via email at support@iscodingforyou.com, and our customer support operates 24/7. We are here to assist you promptly and efficiently.")}
        </p> 

        <h2>
          14. {t("Changes to these Terms of Use")}
        </h2>
        <p>
          {t("We reserve the right to update these Terms of Use periodically. Users are responsible for regularly reviewing the terms, especially after updates. Continued use of the website constitutes acceptance of the new Terms of Use. The effective date of the latest version is indicated at the bottom of this page.")}
        </p>

        <p>
          {t("Effective Date: 27.04.2023")}
        </p>

        <p>
          {t("If you have any questions or concerns about these Terms of Use, please contact us at support@iscodingforyou.com")}
        </p>
      </div>
    </div>
  );
};

export default TermsOfUse;
